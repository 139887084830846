import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/layout.css"

const Contact = ({ intl }) => (
  <Layout>
    <SEO lang={intl.locale} title={intl.formatMessage({ id: "contact.title" })} />
    <h1>
      <FormattedMessage id="contact.title" />
    </h1>
    <p>
      <FormattedMessage id="contact.text" />
      <br />
      <a className="textlink-blue"
        href="mailto:dilia.fuentes@trascendemos.com"
      >
        dilia.fuentes(at)trascendemos.com
    </a>
    </p>

    <p>
      <FormattedMessage id="contact.call" />
    </p>
  </Layout>
)

export default injectIntl(Contact)
